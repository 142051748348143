<template>
  <div>
    <title-bar :title="$t('navigations.employees')" />
    <div v-if="rows" class="custom-table">
      <table-actions
        :actions="['search', 'addBtn', 'perPage']"
        :addBtnText="$t('messages.employeeAdd')"
        :addBtnLink="$helper.getAddUrl('employee')"
        @onSearchInput="inputChange"
        @perPageChange="perPageChange"
        :defaultPerPage="perPage"
      />
      <table-lite
        :is-slot-mode="true"
        :is-loading="isLoading"
        :columns="columns"
        :rows="rows"
        :sortable="{}"
        @is-finished="isLoading = false"
      >
        <template v-slot:name="data">
          <strong>{{
            data.value.givenName + " " + data.value.familyName
          }}</strong>
        </template>
        <template v-slot:telephone="data">
          {{ data.value.telephone }}
          <call-btn
            class="ml-2"
            v-if="data.value.telephone"
            :number="data.value.telephone"
          />
        </template>
        <template v-slot:email="data">
          {{ data.value.email }}
        </template>
        <template v-slot:address="data">
          <span  v-if="data.value.address">
            {{ data.value.address.streetAddress }}
          </span>
        </template>
        <template v-slot:id="data">
          <div class="just-buttons">
            <edit-btn :path="$helper.getEditUrl('employee', data.value.id)" />
            <delete-btn @pressDelete="deleteEmployee(data.value['@id'])" />
          </div>
        </template>
      </table-lite>
      <table-pagination
        v-model="currentPage"
        :total="pages"
        :per-page="perPage"
        @input="onChangePage"
      />
    </div>
  </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import TableLite from "vue3-table-lite";
import TablePagination from "@/components/Table/TablePagination.vue";
import TableActions from "@/components/Table/TableActions.vue";
import CallBtn from "@/components/Buttons/CallBtn.vue";
import EditBtn from "@/components/Buttons/EditBtn.vue";
import DeleteBtn from "@/components/Buttons/DeleteBtn.vue";
import ResourceUtils from "@/mixins/ResourceUtils";

export default {
  name: "AdminEmployeesListing",
  components: {
    TableLite,
    TablePagination,
    TableActions,
    CallBtn,
    EditBtn,
    DeleteBtn,
    TitleBar,
  },
  mixins: [ResourceUtils],
  data() {
    return {
      search: "",
      isLoading: false,
      rows: null,
      perPage: this.$store.state.defaultPerPage,
      currentPage: 1,
      pages: 0,
      columns: [
        {
          label: this.$t('forms.name'),
          field: "name",
          sortable: false,
        },
        {
          label: this.$t('forms.telephone'),
          field: "telephone",
          sortable: false,
        },
        {
          label: this.$t('forms.email'),
          field: "email",
          sortable: false,
        },
        {
          label: this.$t('forms.address'),
          field: "address",
          sortable: false,
        },
        {
          label: this.$t('forms.actions'),
          field: "id",
          sortable: false,
          width: "120px",
        },
      ],
    };
  },
  created() {
    this.loadEmployees();
  },
  methods: {
    loadEmployees() {
      const params = {
        itemsPerPage: this.perPage,
        page: this.currentPage,
        role: ["ROLE_EMPLOYEE"],
      };
      if (this.search && this.search.length) {
        params.search = this.search;
      }
      this.$Users.getCollection({ params }, 'client_list').then((response) => {
        this.pages = response.data["hydra:totalItems"];
        this.rows = response.data["hydra:member"];
      });
    },
    onChangePage(page) {
      this.currentPage = page;
      this.loadEmployees();
    },
    deleteEmployee(url) {
      this.deleteByUrl(this.$Users, url, this.$t('messages.employeeDeleted'), null, this.loadEmployees, this.error);
    },
    inputChange(value) {
      this.currentPage = 1;
      this.search = value;
      this.loadEmployees();
    },
    onPageChange() {
      this.loadEmployees();
    },
    perPageChange(num) {
      this.currentPage = 1;
      this.perPage = num;
      this.loadEmployees();
    },
    error() {
      this.$emit("clearAction");
    },
  },
};
</script>

<style lang="scss">
</style>
